import { Component, OnInit } from '@angular/core';
import { DialogType } from '../dialog/dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  dialogVisible: boolean = false;
  dialogType?: DialogType;

  constructor() {}

  ngOnInit(): void {}

  onClickOpenDialogButton(type: DialogType) {
    this.dialogType = type;
    this.dialogVisible = true;
  }
}
