<div class="myths">
  <div class="myths__description">
    Несмотря на все выгоды, в России сложилось множество
    <span class="blue">мифов</span>
    о банкротстве, которые
  </div>
  <div class="myths__title">не имеют ничего общего с реальностью:</div>
  <div class="myths__items">
    <div class="myths__item myths__item_1">
      <div class="block block_myth">
        <div class="block__title red">
          <span class="icon icon_x"></span>
          Миф:
        </div>
        <div class="block__description black">Я больше никогда не смогу получить кредит</div>
      </div>
      <div class="block block_reality">
        <div class="block__title white">
          <span class="icon icon_check"></span>
          В реальности:
        </div>
        <div class="block__description white">
          При выдаче кредита банк оценивает вашу платежеспособность — скоринговый балл, на который банкротство никак не влияет. Повысить
          скоринговый балл можно с помощью регулярной оплаты небольшого кредита или высокого «белого» дохода
        </div>
      </div>
    </div>
    <div class="myths__item myths__item_2">
      <div class="block block_myth">
        <div class="block__title red">
          <span class="icon icon_x"></span>
          Миф:
        </div>
        <div class="block__description black">Я больше не смогу выехать за границу</div>
      </div>
      <div class="block block_reality">
        <div class="block__title white">
          <span class="icon icon_check"></span>
          В реальности:
        </div>
        <div class="block__description white">
          Ограничение на выезд за границу распространяется только на клиентов, имеющих недвижимость или дорогое имущество за рубежом, чтобы
          избежать его продажи или сокрытия. В иных случаях вы можете свободно выезжать за границу
        </div>
      </div>
    </div>
    <div class="myths__item myths__item_3">
      <div class="block block_myth">
        <div class="block__title red">
          <span class="icon icon_x"></span>
          Миф:
        </div>
        <div class="block__description black">Если я стану банкротом, коллекторы будут продолжать меня беспокоить</div>
      </div>
      <div class="block block_reality">
        <div class="block__title white">
          <span class="icon icon_check"></span>
          В реальности:
        </div>
        <div class="block__description white">
          Подача документов в суд занимает обычно от одной недели до месяца, после чего запускается процесс банкротства. С этого момента ни
          коллекторы, ни судебные приставы не могут повлиять на ход дела, у них больше нет никакого смысла беспокоить вас
        </div>
      </div>
    </div>
    <div class="myths__item myths__item_4">
      <div class="block block_myth">
        <div class="block__title red">
          <span class="icon icon_x"></span>
          Миф:
        </div>
        <div class="block__description black">Через банкротство спишется только 1 из кредитов, а остальные долги останутся</div>
      </div>
      <div class="block block_reality">
        <div class="block__title white">
          <span class="icon icon_check"></span>
          В реальности:
        </div>
        <div class="block__description white">
          По закону вы обязаны включить в перечень всех ваших кредиторов. Поэтому невозможно списать долги только по одному кредиту, не
          затронув остальные
        </div>
      </div>
    </div>
    <div class="myths__item myths__item_5">
      <div class="block block_myth">
        <div class="block__title red">
          <span class="icon icon_x"></span>
          Миф:
        </div>
        <div class="block__description black">Став банкротом, в будущем я не смогу получить хорошую работу или открыть бизнес</div>
      </div>
      <div class="block block_reality">
        <div class="block__title white">
          <span class="icon icon_check"></span>
          В реальности:
        </div>
        <div class="block__description white">
          Вы сможете открыть ИП или любую организацию сразу же после банкротства, поручив при этом руководящую должность другому лицу на
          ближайшие 3 – 5 лет. Других ограничений для вас не будет ни в одной из сфер деятельности
        </div>
      </div>
    </div>
    <div class="myths__item myths__item_6">
      <div class="block block_myth">
        <div class="block__title red">
          <span class="icon icon_x"></span>
          Миф:
        </div>
        <div class="block__description black">Чтобы оформить банкротство, нужно иметь долг от 500 000 руб.</div>
      </div>
      <div class="block block_reality">
        <div class="block__title white">
          <span class="icon icon_check"></span>
          В реальности:
        </div>
        <div class="block__description white">
          Вы можете объявить себя банкротом независимо от суммы долга, если предвидите свою неплатежеспособность. На практике экономически
          оправдано оформлять банкротство, если вы потратите на процедуру меньше, чем сумма вашего долга
        </div>
      </div>
    </div>
  </div>
</div>
