<div class="numbers">
  <div class="numbers__description blue">О компании «Гарант»</div>
  <div class="numbers__title blue">в цифрах</div>
  <div class="numbers__items">
    <div class="item">
      <i class="item__icon item__icon_1"></i>
      <div class="item__number green">2013</div>
      <div class="item__description">год основания</div>
    </div>
    <div class="item">
      <i class="item__icon item__icon_2"></i>
      <div class="item__number green">220</div>
      <div class="item__description">юристов в штате</div>
    </div>
    <div class="item">
      <i class="item__icon item__icon_3"></i>
      <div class="item__number green">> 310 млрд. руб.</div>
      <div class="item__description">долгов списано клиентам</div>
    </div>
    <div class="item">
      <i class="item__icon item__icon_4"></i>
      <div class="item__number green">5800+</div>
      <div class="item__description">выигранных дел</div>
    </div>
  </div>
</div>
