<!-- <h1 class="header">Юридическая фирма «Гарант»</h1>

<div class="animation">
  <div class="penrose">
    <div class="triangle"></div>
    <div class="triangle"></div>
    <div class="triangle"></div>
    <div class="triangle"></div>
    <div class="triangle"></div>
    <div class="triangle"></div>
    <div class="ball"></div>
  </div>
</div>

<div class="footer">
  <h2>Сайт находится в разработке</h2>
  <h3>Контакты: <a href="tel:+79159050441">+7 (915) 905-04-41</a></h3>
</div> -->

<div class="blocks">
  <app-header></app-header>
  <app-bankruptcy></app-bankruptcy>
  <app-get-bankruptcy></app-get-bankruptcy>
  <app-benefits></app-benefits>
  <app-myths></app-myths>
  <app-why-us></app-why-us>
  <app-chain></app-chain>
  <app-house></app-house>
  <app-how-we-work></app-how-we-work>

  <!-- <app-numbers></app-numbers> -->

  <app-request-for-consultation></app-request-for-consultation>
  <app-faq></app-faq>
  <app-request-for-service></app-request-for-service>
  <app-contacts></app-contacts>
</div>

<router-outlet></router-outlet>
