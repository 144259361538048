import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chain',
  templateUrl: './chain.component.html',
  styleUrls: ['./chain.component.scss'],
})
export class ChainComponent implements OnInit {
  dialogVisible: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  onClickOpenDialogButton() {
    this.dialogVisible = true;
  }
}
