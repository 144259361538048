import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-request-for-consultation',
  templateUrl: './request-for-consultation.component.html',
  styleUrls: ['./request-for-consultation.component.scss'],
})
export class RequestForConsultationComponent implements OnInit {
  dialogVisible: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  onClickOpenDialogButton() {
    this.dialogVisible = true;
  }
}
