<div class="why-us">
  <div class="why-us__title black">
    Почему
    <span class="nowrap">обращаются к нам?</span>
  </div>
  <div class="why-us__block">
    <div class="icon icon_1"></div>
    <div class="title blue">Гарантия</div>
    <div class="description black">
      Для Вашего спокойствия
      <b>мы гарантируем возврат оплаты</b>
      в случае отсутствия списания долгов после процедуры. Но, если мы взялись за дело, мы всегда добиваемся результата.
    </div>
  </div>
  <div class="why-us__block">
    <div class="icon icon_2"></div>
    <div class="title blue">Честно</div>
    <div class="description black">
      <b>Мы не обманываем с ценой:</b>
      не называем заниженные суммы и не затягиваем процедуру для своей выгоды. Реальная стоимость всей процедуры от 90 000 рублей. Наши
      эксперты замотивированы только на быстрое положительное завершение дел.
    </div>
  </div>
  <!-- <div class="why-us__block">
    <div class="icon icon_3"></div>
    <div class="title blue">Можем сохранить имущество</div>
    <div class="description black">
      Мы
      <b>сможем вывести ваше имущество</b>
      таким образом, чтобы его НЕ забрали в счёт долга – данная услуга стоит на 50% дороже обычного банкротства
    </div>
  </div> -->
  <div class="why-us__block">
    <div class="icon icon_4"></div>
    <div class="title blue">Профессионально</div>
    <div class="description black">
      Перед началом работ мы подробно
      <b>объясним все тонкости</b>
      процедуры с учетом именно Вашей ситуации,
      <b>досконально изучив Ваше дело.</b>
    </div>
  </div>
  <div class="why-us__block">
    <div class="icon icon_5"></div>
    <div class="title blue">Предоставляем рассрочку</div>
    <div class="description black">
      <b>Удобные варианты рассрочки.</b>
      Нет необходимости вносить все средства сразу. Пошаговая оплата - Вы видите качество работы и результат, после чего оплачиваете
      следующий месяц процедуры.
    </div>
  </div>
  <div class="why-us__block">
    <div class="icon icon_6"></div>
    <div class="title blue">Поможем</div>
    <div class="description black">
      Вы можете
      <b>проконсультироваться</b>
      с опытными специалистами по списанию долгов
      <b>абсолютно бесплатно</b>
      и это вас ни к чему не обязывает.
    </div>
  </div>
</div>
