import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export type DialogType = 'call' | 'consultation' | 'thanks' | 'bankruptcy' | 'bankruptcy-2' | 'request';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  @Input() visible: boolean = false;
  @Input() type?: DialogType;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter(false);

  mask = ['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
  phone: string = '';

  constructor(private http: HttpClient) {}

  ngOnInit(): void {}

  onClickOpenDialogButton(type: DialogType) {
    this.type = type;
    this.visibleChange.emit(true);
  }

  onClickCloseDialogButton() {
    this.visibleChange.emit(false);
  }

  onClickSaveButton() {
    console.log(this.phone);
    if (this.phone.length < 18) return;

    // TODO: send mail backend
    this.http
      .post('/api/api.php', { command: 'mail', phone: this.phone })
      .toPromise()
      .then(res => {
        console.log(res);
      });

    this.visibleChange.emit(false);
  }
}
