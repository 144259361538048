<div class="header__wrap">
  <div class="header">
    <div class="header__row header__row_top">
      <div class="header__logo"></div>
      <div class="contacts">
        <!-- <div class="header__social-button">
          <div class="social-button social-button_vk"></div>
          <div class="social-button social-button_you"></div>
          <div class="social-button social-button_insta"></div>
          <div class="social-button social-button_ok"></div>
        </div> -->
        <a class="header__call-phone">
          <a class="phone" href="tel:+79159050441">+7 (915) 905-04-41</a>
          <div class="get-call" (click)="onClickOpenDialogButton('call')">
            <i class="icon"></i>
            Заказать звонок
          </div>
        </a>
      </div>
    </div>
    <div class="header__row header__row_center">
      <h1 class="header__title">Банкротство физлиц</h1>
      <h2 class="header__description">в Нижнем Новгороде, с возможностью сохранения вашего имущества</h2>
      <div class="header__note">
        <i class="icon"></i>
        Гарантируем списание долгов или вернем
        <span class="green">100%</span>
        средств
      </div>
    </div>
    <div class="header__row header__row_center">
      <div class="header__backgroung-text">Банкротство</div>
      <div class="header__button button" (click)="onClickOpenDialogButton('consultation')">
        <!-- <div class="button__text" id="button_test_1">Получить консультацию</div> -->
      </div>
      <div class="header__row header__row_bottom">
        <div class="header__advantages">
          <div class="advantages">
            <div class="advantages__item">
              <i class="advantages__icon advantages__icon_1"></i>
              <div class="advantages__description">
                Возможна
                <b>оплата услуг в рассрочку</b>
              </div>
            </div>
            <div class="advantages__item">
              <i class="advantages__icon advantages__icon_2"></i>
              <div class="advantages__description">
                Поможем сохранить
                <b>максимум имущества</b>
              </div>
            </div>
            <div class="advantages__item">
              <i class="advantages__icon advantages__icon_3"></i>
              <div class="advantages__description">
                Стоимость наших услуг –
                <b>от 90000 руб.</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-dialog *ngIf="dialogVisible" [type]="dialogType" [(visible)]="dialogVisible"></app-dialog>
</div>
