<div class="request-for-service__wrap">
  <div class="request-for-service">
    <div class="request-for-service__title white">Оставьте заявку на услугу банкротства под ключ</div>
    <div class="request-for-service__description white">
      В случае, если у нас не получится списать ваши долги в рамках банкротства без сохранения имущества – вернем до 100% средств за наши
      услуги
    </div>
    <button class="request-for-service__button button button_yellow-black" (click)="onClickOpenDialogButton()">
      <div class="button__text">Получить банкротство с гарантией</div>
      <i class="button__icon"></i>
    </button>
  </div>
</div>

<app-dialog *ngIf="dialogVisible" type="bankruptcy-2" [(visible)]="dialogVisible"></app-dialog>
