<div class="bankruptcy">
  <div class="bankruptcy__title">Что представляет собой банкротство?</div>
  <div class="bankruptcy__top">
    <div class="block">
      <div class="block__left">
        <div class="big">Банкротство</div>
        <img src="../../assets/bankruptcy/wallet.svg" alt="Картинка" />
      </div>
      <div class="block__rigth">
        <div class="big">— Это</div>
        <div class="small">
          неспособность
          <span class="blue">физического</span>
          или
          <span class="blue">юридического</span>
          лица оплатить финансовую задолженность перед кредитором или государством
        </div>
      </div>
      <div class="block__bottom">
        <div class="small">
          На территории России банкротство — это
          <span class="red">практически</span>
          <span class="blue">единственный легальный способ</span>
          избавиться от долговых обязательств
        </div>
      </div>
    </div>
  </div>
  <div class="bankruptcy__center">
    <div class="block">
      <div class="block__top">
        <div class="big">При этом</div>
      </div>
      <div class="block__left">
        <img src="../../assets/bankruptcy/hammer.svg" alt="Картинка" />
      </div>
      <div class="block__rigth">
        <div class="small">
          статус финансовой несостоятельности должен быть
          <span class="blue">официально признан</span>
          уполномоченным государственным органом
        </div>
      </div>
    </div>
  </div>
  <div class="bankruptcy__bottom">
    <div class="block">
      <div class="medium">
        А для того, чтобы процедура банкротства прошла быстро, безопасно и
        <span class="blue">на выгодных для вас условиях</span>
        — обращайтесь в нашу юридическую компанию
      </div>
    </div>
  </div>
</div>
