<div class="get-bankruptcy__wrap">
  <div class="get-bankruptcy">
    <div>
      <div class="get-bankruptcy__title">Избавьтесь от долгов</div>
      <div class="get-bankruptcy__description">
        без юридических рисков,
        <b class="nowrap">с возможностью</b>
      </div>
    </div>
    <div class="get-bankruptcy__advantages">
      <div class="advantages">
        <div class="advantages__item">
          <i class="advantages__icon advantages__icon_1"></i>
          <span class="advantages__description">
            сохранения
            <b>имущества</b>
          </span>
        </div>
        <div class="advantages__item">
          <i class="advantages__icon advantages__icon_2"></i>
          <span class="advantages__description">
            <b>прав</b>
            на вылет за границу
          </span>
        </div>
      </div>
    </div>
    <div class="get-bankruptcy__button">
      <button class="button button_yellow-black" (click)="onClickOpenDialogButton()">
        <div class="button__text">Получить банкротство безопасно</div>
        <i class="button__icon"></i>
      </button>
    </div>
  </div>
</div>

<app-dialog *ngIf="dialogVisible" type="bankruptcy" [(visible)]="dialogVisible"></app-dialog>
