<div class="request-for-consultation__wrap">
  <div class="request-for-consultation">
    <div class="request-for-consultation__title white">Оставьте заявку на бесплатную консультацию с юристом нашей компании «Гарант»</div>
    <div class="request-for-consultation__description white">
      В ходе консультации мы определим, можно ли списать ваши долги через процедуру банкротства
    </div>
    <button class="request-for-consultation__button button button_yellow-black" (click)="onClickOpenDialogButton()">
      <div class="button__text">Получить консультацию бесплатно</div>
    </button>
  </div>
</div>

<app-dialog *ngIf="dialogVisible" type="consultation" [(visible)]="dialogVisible"></app-dialog>
