<div class="contacts">
  <div class="contacts__block">
    <div class="contacts__title blue">
      <div class="small">Остались</div>
      <div class="big">Вопросы?</div>
    </div>
    <div class="contacts__description blue">Свяжитесь с нами любым удобным вам способом</div>
    <div class="contacts__content">
      <div class="phone">
        <span class="label">Звоните:</span>
        <a class="value" href="tel:+79159050441">+7 (915) 905-04-41</a>
      </div>
      <div class="email">
        <span class="label">Пишите:</span>
        <a class="value" href="mailto:ugra.52@mail.ru">ugra.52@mail.ru</a>
      </div>
      <!-- <div class="social-buttons">
        <div class="social-button social-button_vk"></div>
        <div class="social-button social-button_you"></div>
        <div class="social-button social-button_insta"></div>
        <div class="social-button social-button_ok"></div>
      </div> -->
    </div>
  </div>
  <div class="contacts__map">
    <div class="address__wrap">
      <div class="address white">
        <div class="address__icon"></div>
        <div class="address__title">Наш офис находится по адресу:</div>
        <!-- <div class="address__value"> -->
        <a
          class="address__value white"
          href="https://yandex.ru/maps/?um=constructor%3Aca7bb066eaecfb22c6f03e898b2659acbb43d28815c01f1bd729b015ca66d819&source=constructorLink"
          target="_blank"
        >
          г. Нижний Новгород, ул. Малая Покровская, д. 18, офис 207
        </a>
        <!-- </div> -->
      </div>
    </div>
    <iframe
      src="https://yandex.ru/map-widget/v1/?um=constructor%3Aca7bb066eaecfb22c6f03e898b2659acbb43d28815c01f1bd729b015ca66d819&amp;source=constructor"
      width="100%"
      height="690"
      frameborder="0"
    ></iframe>
  </div>
</div>
