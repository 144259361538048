<div class="house">
  <div class="house__left">
    <!-- <img src="../../assets/house/house.png" height="580" alt="картинка" /> -->
  </div>
  <div class="house__rigth">
    <div class="title white">
      Мы поможем вам пройти процедуру банкротства
      <span class="nowrap">с возможностью</span>
    </div>
    <div class="description white">сохранения имущества</div>
    <button class="button button_yellow-black" (click)="onClickOpenDialogButton()">
      <div class="button__text">Списать до 100% долгов</div>
    </button>
  </div>
</div>

<app-dialog *ngIf="dialogVisible" type="bankruptcy" [(visible)]="dialogVisible"></app-dialog>
