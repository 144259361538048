<div class="dialog__wrap">
  <div class="dialog">
    <div class="dialog__close-button" (click)="onClickCloseDialogButton()">+</div>
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="'call'">
        <div class="dialog__title">Заказать звонок</div>
        <div class="dialog__description">
          Заполните свои контактные данные, и
          <b>в ближайшее время</b>
          мы свяжемся с вами по указанному номеру
        </div>
        <div class="dialog__actions">
          <div class="field">
            <input type="tel" [textMask]="{ mask: mask }" placeholder="+7 (___) ___-__-__" [(ngModel)]="phone" autofocus />
          </div>
          <div class="button" (click)="onClickSaveButton()">
            <div class="button__text white">Заказать звонок</div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'thanks'">
        <div class="dialog__title">Мы получили вашу заявку</div>
        <div class="dialog__description">В ближайшее время мы свяжемся с вами по указанному номеру телефона</div>
        <div class="dialog__actions">
          <div class="button" (click)="onClickCloseDialogButton()">
            <div class="button__text white">Хорошо</div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'consultation'">
        <div class="dialog__title">Получить консультацию</div>
        <div class="dialog__description">
          Чтобы получить консультацию, заполните свои контактные данные, и
          <b>мы свяжемся с вами</b>
          по указанному номеру
        </div>
        <div class="dialog__actions">
          <div class="field">
            <input type="tel" [textMask]="{ mask: mask }" placeholder="+7 (___) ___-__-__" [(ngModel)]="phone" autofocus />
          </div>
          <div class="button" (click)="onClickSaveButton()">
            <div class="button__text white">Получить бесплатную консультацию</div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'bankruptcy'">
        <div class="dialog__title">Получить банкротство</div>
        <div class="dialog__description">
          Запишитесь к нам на консультацию, чтобы мы могли обсудить все детали
          <b>вашего безопасного банкротства</b>
        </div>
        <div class="dialog__actions">
          <div class="field">
            <input type="tel" [textMask]="{ mask: mask }" placeholder="+7 (___) ___-__-__" [(ngModel)]="phone" autofocus />
          </div>
          <div class="button" (click)="onClickSaveButton()">
            <div class="button__text white">Получить банкротство безопасно</div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'request'">
        <div class="dialog__title">Оставить заявку</div>
        <div class="dialog__description">
          Заполните свои контактные данные, чтобы мы связались с вами по указанному номеру и обсудили ваш случай
          <b>для списания 100% долгов</b>
        </div>
        <div class="dialog__actions">
          <div class="field">
            <input type="tel" [textMask]="{ mask: mask }" placeholder="+7 (___) ___-__-__" [(ngModel)]="phone" autofocus />
          </div>
          <div class="button" (click)="onClickSaveButton()">
            <div class="button__text white">Списать до 100% долгов</div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'bankruptcy-2'">
        <div class="dialog__title">Оставить заявку</div>
        <div class="dialog__description">
          Чтобы получить консультацию, заполните свои контактные данные, и мы свяжемся с вами по указанному номеру
        </div>
        <div class="dialog__actions">
          <div class="field">
            <input type="tel" [textMask]="{ mask: mask }" placeholder="+7 (___) ___-__-__" [(ngModel)]="phone" autofocus />
          </div>
          <div class="button" (click)="onClickSaveButton()">
            <div class="button__text white">Получить банкротство с гарантией</div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
