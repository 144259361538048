<div class="how-we-work">
  <div class="how-we-work__title">Как мы работаем?</div>
  <div class="block">
    <div class="block__icon">
      <div class="text">1</div>
    </div>
    <div class="block__title">Звонок или заявка</div>
    <div class="block__description">Вы оставляете заявку на сайте или звоните по указанному на нашем сайте номеру</div>
  </div>
  <div class="block">
    <div class="block__icon">
      <div class="text">2</div>
    </div>
    <div class="block__title">Бесплатная консультация</div>
    <div class="block__description">Наш специалист определяет, можно ли списать ваши долги через процедуру банкротства</div>
  </div>
  <div class="block">
    <div class="block__icon">
      <div class="text">3</div>
    </div>
    <div class="block__title">Встреча в офисе</div>
    <div class="block__description">Вы приезжаете к нам в офис для личной консультации</div>
  </div>
  <div class="block">
    <div class="block__icon">
      <div class="text">4</div>
    </div>
    <div class="block__title">Анализ ситуации</div>
    <div class="block__description">Если ваша ситуация сложная, в таком случае потребуется платный анализ ситуации</div>
  </div>
  <div class="block">
    <div class="block__icon">
      <div class="text">5</div>
    </div>
    <div class="block__title">Заключение договора</div>
    <div class="block__description">Согласовываем с вами условия оплаты и заключаем договор на предоставление услуг</div>
  </div>
  <div class="block">
    <div class="block__icon">
      <div class="text">6</div>
    </div>
    <div class="block__title">Подача документов в суд</div>
    <div class="block__description">Собираем необходимые документы во всех инстанциях и подаём их в арбитражный суд</div>
  </div>
  <div class="block">
    <div class="block__icon">
      <div class="text">7</div>
    </div>
    <div class="block__title">Назначение финансового управляющего</div>
    <div class="block__description">С нашей стороны будет назначен лояльный к вам арбитражный управляющий</div>
  </div>
  <div class="block">
    <div class="block__icon">
      <div class="text">8</div>
    </div>
    <div class="block__title">Процедура банкротства</div>
    <div class="block__description">В ходе банкротства идет частичная реализация имущества, если это обсуждалось заранее</div>
  </div>
  <div class="block">
    <div class="block__icon">
      <div class="text">9</div>
    </div>
    <div class="block__title">Списание долгов</div>
    <div class="block__description">Итогом нашей работы будут полностью списанные ваши долги по решению суда</div>
  </div>
  <div class="how-we-work__button-wrap" (click)="onClickOpenDialogButton()">
    <div class="how-we-work__button button"></div>
  </div>
</div>

<app-dialog *ngIf="dialogVisible" type="request" [(visible)]="dialogVisible"></app-dialog>
