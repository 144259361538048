<div class="benefits">
  <div class="benefits__title">
    <span class="black">Какие выгоды</span>
    несет для вас процедура банкротства?
  </div>
  <div class="benefits__items">
    <div class="item">
      <div class="item__title">Списание до 100% долгов</div>
      <div class="item__img">
        <img src="../../assets/benefits/img-1.png" alt="картинка" />
      </div>
      <div class="item__description">
        <span class="blue">Банкротство позволяет списать практически все виды долгов:</span>
        кредиты, долги физическим лицам, ЖКХ, налоги, обязательства ИП
      </div>
    </div>
    <div class="item">
      <div class="item__title">Ваше спокойствие</div>
      <div class="item__img">
        <img src="../../assets/benefits/img-2.png" alt="картинка" />
      </div>
      <div class="item__description">
        <span class="blue">Наконец-то вы сможете спать спокойно</span>
        — вам больше не придется общаться с кредиторами и коллекторами, так как у вас больше не будет долгов
      </div>
    </div>
    <div class="item">
      <div class="item__title">Сохранение имущества</div>
      <div class="item__img">
        <img src="../../assets/benefits/img-3.png" alt="картинка" />
      </div>
      <div class="item__description">
        Мы можем сделать так, чтобы
        <span class="blue">у вас осталось максимум имущества,</span>
        помимо единственного жилья
      </div>
    </div>
  </div>
</div>
