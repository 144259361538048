import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-get-bankruptcy',
  templateUrl: './get-bankruptcy.component.html',
  styleUrls: ['./get-bankruptcy.component.scss'],
})
export class GetBankruptcyComponent implements OnInit {
  dialogVisible: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  onClickOpenDialogButton() {
    this.dialogVisible = true;
  }
}
