<div class="faq">
  <div class="faq__title">
    Вопросы –
    <span class="black">ответы</span>
  </div>
  <div class="block">
    <div class="block__icon">
      <i class="icon"></i>
    </div>
    <div class="block__title">Что такое процедура банкротства?</div>
    <div class="block__description">
      Банкротство — это процедура, которая помогает законно освободиться от долгов, если нет возможности их выплачивать.
    </div>
  </div>
  <div class="block">
    <div class="block__icon">
      <i class="icon"></i>
    </div>
    <div class="block__title">Кто может подать на банкротство?</div>
    <div class="block__description">
      Любой гражданин Российской Федерации, который не в силах исполнять свои долговые обязательства, на законных основаниях может подать
      заявление в арбитражный суд о признании себя банкротом.
    </div>
  </div>
  <div class="block">
    <div class="block__icon">
      <i class="icon"></i>
    </div>
    <div class="block__title">Это правда законно?</div>
    <div class="block__description">
      Списание долгов производится Арбитражным судом на основании Федерального закона от 26.10.2002 N 127-ФЗ «О несостоятельности
      (банкротстве)».
    </div>
  </div>
  <div class="block">
    <div class="block__icon">
      <i class="icon"></i>
    </div>
    <div class="block__title">Какие долги нельзя списать?</div>
    <div class="block__description">
      Нельзя списать задолженности по возмещению вреда, причиненного жизни и/ или здоровью, по выплате заработной платы и выходного пособия,
      по возмещению морального вреда, а так же, по взысканию алиментов.
    </div>
  </div>
  <div class="block">
    <div class="block__icon">
      <i class="icon"></i>
    </div>
    <div class="block__title">Какие есть минусы при банкротстве физлица?</div>
    <div class="block__description">
      Пока идет банкротство, счета должника заблокированы. На время процедуры, банкрот из официальных доходов получает только прожиточный
      минимум. Во время процедуры банкрот не имеете права совершать сделки, кроме бытовых.
    </div>
  </div>
  <div class="block">
    <div class="block__icon">
      <i class="icon"></i>
    </div>
    <div class="block__title">Какие есть риски при банкротстве физлица?</div>
    <div class="block__description">
      Риск неосвобождения от долгов. Риск выхода арбитражного управляющего из процедуры банкротства. Риск продажи доли в общем имуществе
      супругов. На бесплатной консультации мы проверим Вашу ситуацию на возможные риски при списании долгов и разработаем оптимальную
      стратегию списания долгов.
    </div>
  </div>
  <div class="block">
    <div class="block__icon">
      <i class="icon"></i>
    </div>
    <div class="block__title">Какие последствия у банкротства физлица?</div>
    <div class="block__description">
      В течение 3 лет банкрот не сможет участвовать в управлении юридическим лицом, в течение 5 лет финансовой организацией, в течение 10
      лет банком. В течение 5 лет запрещается скрывать факт своего банкротства при получении кредитов и займов. Нельзя повторно объявить
      себя банкротом в течение 5 лет. Потеря имущества, кроме единственного жилья.
    </div>
  </div>
  <div class="block">
    <div class="block__icon">
      <i class="icon"></i>
    </div>
    <div class="block__title">Какие условия прохождения процедуры банкротства?</div>
    <div class="block__description">
      Для каждого гражданина условия работы подбираются индивидуально, исходя из жизненной ситуации, универсального способа нет. Вы можете
      быть уверены, что мы подберем для Вас максимально выгодные условия работы.
    </div>
  </div>
  <div class="block">
    <div class="block__icon">
      <i class="icon"></i>
    </div>
    <div class="block__title">Как долго длится процедура банкротства?</div>
    <div class="block__description">
      В среднем, с момента подачи заявления о банкротстве в Арбитражный суд и до полного списания долга проходит от 6 до 12 месяцев.
    </div>
  </div>
  <div class="block">
    <div class="block__icon">
      <i class="icon"></i>
    </div>
    <div class="block__title">Сколько стоит процедура банкротства?</div>
    <div class="block__description">
      Стоимость процедуры банкротства рассчитывается индивидуально, исходя из трудозатрат, при этом мы всегда подбираем максимально
      комфортный способ оплаты. На консультации определяется сложность дела и стоимость, оговоренная цена остается неизменной.
    </div>
  </div>
  <div class="block">
    <div class="block__icon">
      <i class="icon"></i>
    </div>
    <div class="block__title">Стоит ли проводить процедуру банкротства самому?</div>
    <div class="block__description">
      Банкротство — это сложная и длительная процедура, во всех тонкостях которой может разобраться только опытный юрист, при этом, повторно
      можно обратиться с заявлением только спустя 5 лет. Иными словами, малейшая ошибка в ваших действиях может повлечь за собой еще 5 лет
      долгового рабства.
    </div>
  </div>
  <div class="block">
    <div class="block__icon">
      <i class="icon"></i>
    </div>
    <div class="block__title">Банкротство с ипотекой возможно?</div>
    <div class="block__description">
      Наличие ипотечного кредита или займа не является препятствием для вступления в процедуру банкротства, при этом необходимо
      анализировать и другие обстоятельства.
    </div>
  </div>
</div>
