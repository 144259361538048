import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { TextMaskModule } from 'angular2-text-mask';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { BankruptcyComponent } from './bankruptcy/bankruptcy.component';
import { GetBankruptcyComponent } from './get-bankruptcy/get-bankruptcy.component';
import { BenefitsComponent } from './benefits/benefits.component';
import { MythsComponent } from './myths/myths.component';
import { WhyUsComponent } from './why-us/why-us.component';
import { ChainComponent } from './chain/chain.component';
import { HouseComponent } from './house/house.component';
import { HowWeWorkComponent } from './how-we-work/how-we-work.component';
import { NumbersComponent } from './numbers/numbers.component';
import { FaqComponent } from './faq/faq.component';
import { RequestForServiceComponent } from './request-for-service/request-for-service.component';
import { RequestForConsultationComponent } from './request-for-consultation/request-for-consultation.component';
import { ContactsComponent } from './contacts/contacts.component';
import { DialogComponent } from './dialog/dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BankruptcyComponent,
    GetBankruptcyComponent,
    BenefitsComponent,
    MythsComponent,
    WhyUsComponent,
    ChainComponent,
    HouseComponent,
    HowWeWorkComponent,
    NumbersComponent,
    FaqComponent,
    RequestForServiceComponent,
    RequestForConsultationComponent,
    ContactsComponent,
    DialogComponent,
  ],
  imports: [BrowserModule, AppRoutingModule, FormsModule, HttpClientModule, TextMaskModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
